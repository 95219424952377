.box-admin {
  float: left;
  width: 259px;
  height: auto;
  margin-left: 20px;
}

.box-admin__title {
  color: #fff;
  background-color: #e80000;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 15px;
}

.box-admin__article {
  float: left;
  background-color: #fff;
  width: 100%;
  height: auto;
  margin-bottom: 16.5px;
}

.box-admin__img {
  float: left;
  width: 100%;
  height: auto;
  position: relative;
}

.box-admin__img img {
  width: 100%;
}

.box-admin__img span {
  box-sizing: border-box;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, .6);
  width: 100%;
  height: 24px;
  padding-left: 8px;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.box-admin__img span a {
  color: #fff;
  line-height: 24px;
  text-decoration: none;
}

.box-admin__perex {
  float: left;
  box-sizing: border-box;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
}

.box-admin__perex a {
  color: #000;
  text-decoration: none;
}

.box-admin__perex a:hover {
  color: #e80000;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.promoArea .celebrityBox img.lazyload {
  position: relative;
}

.category .person picture.loading-placeholder {
  float: left;
  width: 80px;
  height: 80px;
  margin: 1px 6px 1px 1px;
  padding-bottom: 0 !important;
}

.articles .bottomRelatedArticle picture.loading-placeholder {
  float: left;
  width: 150px;
  height: 100px;
  margin: 0 10px 0 0;
  padding-bottom: 0 !important;
}

